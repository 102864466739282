import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

// export const customCurrencyMaskConfig = {
//   align: 'right', prefix: '', thousands: ',', precision: 2, nullable: true, inputMode: CurrencyMaskInputMode.NATURAL
// };

export class AppComponent implements OnInit, OnDestroy {
  active = 1;

  customCurrencyMaskConfig: any = {
    align: 'right', prefix: '', thousands: ',', precision: 2, nullable: true, inputMode: CurrencyMaskInputMode.NATURAL
  };

  step3Form: FormGroup;
  step3FormSub: Subscription;

  ngOnInit(): void {
    this.step3Form = new FormGroup({
      inventoryA: new FormControl('150000'),
      inventoryB: new FormControl('150000'),
      inventoryOIR: new FormControl('0'),
      goodwillA: new FormControl('500000'),
    });

    this.step3FormSub = this.step3Form.valueChanges.subscribe(() => {
        this.step3Form.patchValue({
          inventoryOIR: this.step3Form.controls['inventoryA'].value - this.step3Form.controls['inventoryB'].value
        }, {emitEvent: false});
      }
    );
  }

  onInventoryClick() {
    if (this.step3Form.controls['inventoryA'].value === '350000') {
      this.step3Form.patchValue({
        inventoryA: '150000',
        goodwillA: '500000'
      });
    } else {
      this.step3Form.patchValue({
        inventoryA: '350000',
        goodwillA: '300000'
      });
    }
  }

  ngOnDestroy(): void {
    this.step3FormSub.unsubscribe();
  }
}
